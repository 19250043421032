import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import SEO from '../components/seo';
import './index.scss';
import ProductHeader from '../components/product-header';
// import ArrowRightIcon from '!svg-react-loader!../images/vectors/arrow_right.svg';
import MyHbLogo from '!svg-react-loader!../images/vectors/myhealthbook-logo.svg';
// import SAFeedback from './sa-feedback';
// import BookDemoPage from '../components/book-demo';
import { InstallButton, OfferingItem, OfferingItemContainer } from '../components/extra-components';
import DetailedSpecs from '../components/detailed-specs';
import ComingSoon from '../components/coming-soon';
import Feedback from './sr-feedback';
import { CTypes, myHealthBookFeedbackData } from '../utils/constants';
// import ComingSoon from '../components/coming-soon';
// import * as Constants from '../utils/constants';
// import Feedback from './sr-feedback';
// import { myHealthBookFeedbackData } from '../utils/constants';


const MyHealthBookLogo = () => {
  return (
    <MyHbLogo width={25} height={28} alt={'smart rep logo'} />
  );
};

const offeringContentList = [
  {
    title: 'My Health',
    description: 'Get summary of your health based on your prescriptions, connected devices and personalised screening routine'
  },
  {
    title: 'My Doctors',
    description: 'Manage appointments with your doctors, get digitised consultation notes, clarify doubts, access their dispensary & lab, manage hospitalisation & surgeries, and share feedback'
  },
  {
    title: 'My Records',
    description: 'Access all your health records sorted based on specialisation and chronology'
  },
  {
    title: 'My Payors',
    description: 'Manage health insurance subscriptions, settle claims with minimal hassle and access medical loans'
  }
];
const upgradesOfferingContentList = [
  {
    title: 'My Family Doctor',
    description: 'Get a dedicated general physician for routine guidance, introduction to needed specialists and personalised review of your ongoing treatments'
  }
];

const OfferingDetails = () => {
  const viewDetailsClicked = (page) => {
    return (e) => {
      e.stopPropagation();
      console.log('view details clicked', page, e);
      return true;
    };
  };
  return (
    <>
      <div className='micro-app-offering-header'>
        <h4>Base Offering</h4>
        <p className='offering-pricing'>
          Includes our four most popular micro apps and MedTrail support.<br />
          Price: Free
        </p>
      </div>
      <OfferingItemContainer list={offeringContentList} fullWidth />
    </>
  );
};

const UpgradesOfferingDetails = () => {
  const viewDetailsClicked = (page) => {
    return (e) => {
      e.stopPropagation();
      console.log('view details clicked', page, e);
      return true;
    };
  };
  return (
    <>
      <div className='micro-app-offering-header'>
        <h4>Upgrades</h4>
        <p className='offering-pricing'>
          Powerful micro apps to provide extended health care to you and your loved ones.<br />
          Price: As per your choice of care provider
        </p>
      </div>
      <OfferingItemContainer list={upgradesOfferingContentList} fullWidth />
    </>
  );
};

const MyHBEveryoneDetailsPage = ({ location, navigate }) => {

  useEffect(() => {
    window.scrollTo({ behavior: 'auto', top: 0 });
  }, []);

  const variants = {
    hidden: (custom) => {
      return {
        opacity: 0,
        y: 0
      };
    },
    visible: (custom) => ({
      opacity: 1,
      y: 0
    })
  };


  return (
    <div className='mt-page-root container'>
      <SEO title="MyHealthBook - Offering Details" />
      <div className='page-fixed-header container'>
        <ProductHeader
          title={'MyHealthBook'}
          productImage={MyHealthBookLogo}
          backLinkTo={`/#home-myhealthbook`}
          featureText={`for everyone`}
          rightButton={<InstallButton dynamic={true} />}
          cType={CTypes.MYHB_EVERYONE}
        />
      </div>
      {/*<ComingSoon />*/}
      <motion.div
        className="container-fluid main-container-full sa-page-scroller"
        initial={'hidden'}
        animate={'visible'}
        variants={{
          visible: {
            transition: {
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05
            }
          },
          hidden: {
            transition: {
              duration: 0.3
            }
          }
        }}
      >

        <motion.section variants={variants}>
          <div className='sa-base-description'>
            Modular health journal designed to enable your trusted doctors to serve you as your personal health team.
            Its powerful micro apps simplify your interactions with clinics, hospitals, pharmaceutical companies,
            diagnostic labs and insurance companies to provide you complete control over your health.
          </div>
        </motion.section>

        <div className='row mt-offering-section-container'>
          <motion.section className='row' variants={variants}>
            <OfferingDetails />
          </motion.section>

          <motion.section className='row' variants={variants}>
            <UpgradesOfferingDetails />
          </motion.section>
        </div>

        {/*<div className='row'>*/}
        {/*  <div className='col-lg-4' />*/}
        {/*  <motion.section className='col-lg-4' variants={variants}>*/}
        {/*    <DetailedSpecs>*/}
        {/*      <ComingSoon autoHeight />*/}
        {/*    </DetailedSpecs>*/}
        {/*  </motion.section>*/}
        {/*</div>*/}


        <motion.section variants={variants}>
          <div className='empty-space' />
          <Feedback
            title='Unfiltered feedback from our users'
            data={myHealthBookFeedbackData}
          />
        </motion.section>

      </motion.div>
      <div className='mobile'>
        <InstallButton />
      </div>
      {/*<div className='desktop'>*/}
      {/*  <InstallButton />*/}
      {/*</div>*/}
    </div>
  );
};

export default MyHBEveryoneDetailsPage;
